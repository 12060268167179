import React, {
  ElementRef,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0;
};

type VideoType = {
  src: string;
  videoType: HTMLAttributes<HTMLSourceElement>['itemType'];
};
export default function AutoplayVideo(props: {
  src: string | VideoType[];
  videoType?: HTMLAttributes<HTMLSourceElement>['itemType'];
  className?: string;
}) {
  const input = Array.isArray(props.src)
    ? props.src
    : [{ src: props.src, videoType: props.videoType }];
  const videoParentRef = useRef<ElementRef<'div'>>(null);
  const [promiseFailed, setPromiseFailed] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0] as HTMLVideoElement;

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute('muted', ''); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                if (videoParentRef.current) {
                  videoParentRef.current.style.display = 'none';
                }
                // if promise fails, hide the video and fallback to <img> tag
                setPromiseFailed(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return promiseFailed ? null : ( // eslint-disable-next-line @next/next/no-img-element
    (<div
      ref={videoParentRef}
      style={{ position: 'absolute', inset: 0 }}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
          class="${props.className || ''}"
        >
        ${input.map(
          ({ src, videoType }) => `<source src="${src}" type="${videoType}" />`,
        )}
        </video>`,
      }}
    />)
  );
}
